export default [
  {
    path: 'content.sections',
    merge: true,
    data: {
      contentId: '2G1ox5LWBWIzdTsrbNqqL1', // Content id of section were merge will take place
      componentRef: 'HowWeHelped',
    },
  },
  {
    path: 'content.sections',
    merge: true,
    data: {
      contentId: '57TXMX4eOk0i5La2bQP8Xa', // Content id of section were merge will take place
      componentRef: 'HowWeHelped',
    },
  },
  {
    path: 'content.sections',
    merge: true,
    data: {
      contentId: '1oopfkIHvV9zQWfahYDsKk', // Content id of section were merge will take place
      componentRef: 'Otovo',
    },
  },
]
