<script setup lang="ts">
import { Brand, MainPage } from '@ocp-zmarta/content-sdk'
import useFormattedPageData from '@/composables/useFormattedPageData'
import App from '@/components/layout/App.vue'
import { defineAsyncComponent, hydrateOnVisible, useSSRContext, type Component } from 'vue'
import customData from '@/components/custom/elskling/start-page.ts'
import { getServerState } from '@ocp-zmarta/zsc-plugin-vite-ssr/vue'

const ctx = import.meta.env.SSR
  ? useSSRContext()
  : {
      market: getServerState('market'),
      brand: getServerState('brand'),
      query: getServerState('query'),
    }

let customComponents: Record<string, Component> | undefined

if (ctx?.brand === Brand.Elskling) {
  const rootMargin = { rootMargin: '150px' }
  customComponents = {
    HowWeHelped: defineAsyncComponent({
      loader: async () => await import('@/components/custom/elskling/HowWeHelped.vue'),
      hydrate: hydrateOnVisible(rootMargin),
    }),
    Otovo: defineAsyncComponent({
      loader: async () => await import('@/components/custom/elskling/Otovo.vue'),
      hydrate: hydrateOnVisible(rootMargin),
    }),
  }
}

const { pageData, site } = useFormattedPageData({ ctx, customData })
</script>

<template>
  <app>
    <main-page v-if="pageData && site" :page="pageData" :site="site" :custom-components="customComponents" />
  </app>
</template>

<style scoped lang="scss">
  :deep(.zg-text-section__body) {
    h1:not(:first-child) {
      font-size: 2.5rem !important;
      margin: rhythm(small) 0 rhythm(medium) 0 !important;

      @include mq(large) {
        font-size: 3.5rem !important;
      }
    }
  }
</style>
